import { sanitize } from "isomorphic-dompurify";
import { Button } from "../Global/Button";
import { AnimtionElement } from "./ScrollAnimationModule";
import { motion } from "framer-motion";
import { defaultTransition, stagger_variant } from "../../utils/transition";

const text_variant = {
  initial: {
    y: "150%",
  },
  animate: {
    y: 0,
    transition: { ...defaultTransition },
  },
  exit: {
    y: "-150%",
    transition: defaultTransition,
  },
};

export const button_variant = {
  initial: {
    opacity: 0,
    filter: "blur(10px)",
  },
  animate: {
    opacity: 1,
    filter: "blur(0px)",
    transition: { ...defaultTransition },
  },
  exit: {
    opacity: 0,
    filter: "blur(10px)",
    transition: defaultTransition,
  },
};

export const ScrollElementDesktop = ({
  element,
}: {
  element: AnimtionElement;
}) => {
  return (
    <div className="element-wrapper">
      <div key={"normal-element"} className="element">
        <Element {...element} />
      </div>
    </div>
  );
};
export const ScrollElementTouch = ({
  element,
}: {
  element: AnimtionElement;
}) => {
  return (
    <div className="element-wrapper">
      <motion.div
        initial={"initial"}
        whileInView={"animate"}
        exit={"exit"}
        viewport={{ once: true }}
        variants={stagger_variant}
        className="element"
        key={"animated-element"}
      >
        <Element {...element} />
      </motion.div>
    </div>
  );
};

const Element = ({ content, link, overline, title }: AnimtionElement) => {
  return (
    <>
      <div className="is-clipped">
        <motion.span
          variants={text_variant}
          className="overline"
          dangerouslySetInnerHTML={{ __html: sanitize(overline) }}
        />
      </div>
      <div className="is-clipped">
        <motion.span
          variants={text_variant}
          className="title"
          dangerouslySetInnerHTML={{ __html: sanitize(title) }}
        />
      </div>
      {content && (
        <div className="is-clipped">
          <motion.div
            variants={text_variant}
            className="content"
            dangerouslySetInnerHTML={{ __html: sanitize(content) }}
          />
        </div>
      )}
      {link && (
        <motion.div variants={button_variant} className="is-clipped">
          <Button
            type="primary"
            title={link.title}
            href={link.url}
            target={link.target}
            className="element-button"
          />
        </motion.div>
      )}
    </>
  );
};
