import { DotLottiePlayer } from "@johanaarstein/dotlottie-player";
import { useScroll, useMotionValueEvent, motion } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import AnimationPlayer from "../AnimationPlayer";
import { COLORS, ScrollCompProp } from "./ScrollAnimationModule";
import { ScrollElementDesktop } from "./ScrollElement";

export const ScrollAnimationDesktop = ({
  elements,
  svg_animation,
  activeElementKey,
  setActiveElementKey,
}: ScrollCompProp) => {
  const animation_url = svg_animation
    ? svg_animation.url ?? ""
    : "/scroll_animation.lottie";

  const [animation, setAnimation] = useState<DotLottiePlayer | null>(null);

  const [direction, setDirection] = useState<"up" | "down">("down");

  const elementWrapperRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: elementWrapperRef,
    offset: ["start 70px", "end 70px"],
  });

  useMotionValueEvent(scrollYProgress, "change", (progress) => {
    setDirection(
      Math.sign(progress - scrollYProgress.getPrevious()) === 1 ? "down" : "up"
    );

    const active = Math.round(progress * elements.length);
    setActiveElementKey(
      active < elements.length ? active : elements.length - 1
    );
  });

  useEffect(() => {
    if (animation && activeElementKey < elements.length) {
      const lottie = animation.getLottie();

      const segement: [number, number][] = [];
      if (direction === "down") {
        const { start_frame, end_frame } = elements[activeElementKey].frames;
        segement.push([Number(start_frame), Number(end_frame)]);
      } else {
        const prevIndex =
          activeElementKey + 1 < elements.length
            ? activeElementKey + 1
            : elements.length - 1;
        const { start_frame, end_frame } = elements[prevIndex].frames;
        segement.push([Number(end_frame), Number(start_frame)]);
      }
      lottie?.playSegments(segement, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeElementKey]);

  return (
    <div className="columns is-multiline">
      <div className="column is-5 animation-wrapper">
        <motion.div
          style={{
            // css animation used for lesser javascript code
            backgroundColor: COLORS[activeElementKey],
          }}
          className="animation"
        >
          <AnimationPlayer
            AnimationRef={setAnimation}
            url={animation_url}
            autoplay={false}
            loop={false}
          />
        </motion.div>
      </div>
      <div ref={elementWrapperRef} className="column is-offset-1 is-6">
        {elements.map((element, key) => (
          <ScrollElementDesktop key={key} element={element} />
        ))}
      </div>
    </div>
  );
};
