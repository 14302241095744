import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { Section } from "../Global/Section";
import { AnimationType, LayoutType, LinkType } from "../../utils/baseTypes";
import useWindowSize from "../../hooks/useWindowSize";
import { ScrollAnimationDesktop } from "./ScrollAnimationDesktop";
import { ScrollAnimationTouch } from "./ScrollAnimationTouch";
import { useInView } from "framer-motion";

export type ScrollAnimationModuleType = {
  acf_fc_layout: "scrollanimation";
  scrollanimation: ScrollAnimationType;
  layout: LayoutType;
};

export type ScrollAnimationType = {
  svg_animation: AnimationType | false;
  elements: AnimtionElement[];
};

export type AnimtionElement = {
  frames: {
    start_frame: number;
    end_frame: number;
  };
  overline: string;
  title: string;
  content: string;
  link: LinkType;
};

interface ScrollAnimationProp {
  data: ScrollAnimationModuleType;
}

export interface ScrollCompProp extends ScrollAnimationType {
  setActiveElementKey: Dispatch<SetStateAction<number>>;
  activeElementKey: number;
}

export const COLORS = ["#34309F", "#A9009D", "#007E7D", "#006699", "#C20062"];

const ScrollAnimationModule = ({ data }: ScrollAnimationProp) => {
  const { id, abstand } = data.layout;
  const gap = abstand === "default" ? "" : abstand;

  const { width } = useWindowSize();
  const [activeElementKey, setActiveElementKey] = useState<number>(0);
  const sectionRef = useRef<HTMLDivElement>(null);
  const inView = useInView(sectionRef, { once: true, margin: `50% 0px` });

  return (
    <Section
      id={id}
      ref={sectionRef}
      className={`scroll-animation ${gap}`}
      disableColumns
      isClipped={false}
      style={{
        backgroundColor: COLORS[activeElementKey],
      }}
    >
      {inView && (
        <>
          {width > 1023 ? (
            <ScrollAnimationDesktop
              {...data.scrollanimation}
              setActiveElementKey={setActiveElementKey}
              activeElementKey={activeElementKey}
            />
          ) : (
            <ScrollAnimationTouch
              {...data.scrollanimation}
              setActiveElementKey={setActiveElementKey}
              activeElementKey={activeElementKey}
            />
          )}
        </>
      )}
    </Section>
  );
};

export default ScrollAnimationModule;
